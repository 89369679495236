@import url('../fonts/fonts.css');

*{
    --back-lin: #121212;
    --back-lin-sub: #191919;
    --back-lin-lf: #161616;

    --whiteRGB: 255, 255, 255;
    --padd: 8%;

    --red: #FF3B30;
    --green: #1EC337;
    --yellow: #F5C200;

    --accent: #0070F5;
    --acentHover: #005bc7;
}

.trans-info{
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
}

.info{
    border-radius: 5px;
    padding: 20px;
    border: 1px solid rgba(var(--whiteRGB), .1);
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.info-ds{
    margin-block-start: 0;
    margin-block-end: 0;
    color: white;
    font-size: 13px;
    font-weight: 400;
}

.red-sv > g,
.red-sv > path{
    fill: var(--red);
}

.tab-list{
    display: flex;
    padding-left: var(--padd);
    padding-right: var(--padd);
    padding-top: 15px;
    padding-bottom: 15px;
    gap: 20px;
    border-bottom: 1px solid rgba(var(--whiteRGB), .1);
}

.transfer-app{
    /*background-color: var(--back-lin-sub);*/
    height: -webkit-fill-available;
    padding-left: var(--padd);
    padding-right: var(--padd);
    padding-top: 50px;
}

.tab-cn-wait{
    border-color: var(--yellow) !important;
}

.tab{
    background-color: transparent;
    border-radius: 6px;
    padding: 10px;
    border: 1px solid transparent;
    padding-left: 20px;
    padding-right: 20px;
    color: rgba(var(--whiteRGB), .35);
    font-size: 14px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: .35s;
    display: flex;
    gap: 10px;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-top: 1px solid transparent;
}

.dev-counter{
    border-radius: 8px;
    background-color: var(--back-lin-sub);
    color: white !important;
    font-size: 12px;
    font-weight: 400;
    aspect-ratio: 1 / 1;
    margin-block-start: 0;
    margin-block-end: 0;
}

.tab:hover{
    background-color: rgba(var(--whiteRGB), .05);
}

.tab-sl{
    background-color: var(--back-lin-sub) !important;
    color: white;
    transition: .35s;
    border: 1px solid rgba(var(--whiteRGB), .1);
}

.qr-wrap{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    background-color: rgba(var(--whiteRGB), .05);
    border-radius: 5px;
    width: 50%;
    aspect-ratio: 1 / 1;
    height: 50%;
}

#root{
    height: 100%;
}

.loader {
    width: 48px;
    height: 48px;
    border: 2px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.conn-tut{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.dist-cs{
    display: flex;
    gap: 10px;
}

.btn{
    transition: .35s;
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    outline: none;
}

.btn-sc{
    background-color: transparent;
    color: white;
    border: 2px solid rgba(var(--whiteRGB), .05);
}

.btn-sc:hover{
    background-color: rgba(var(--whiteRGB), .15);
}

.btn-ac{
    background-color: var(--accent);
    color: white;
    border: none;
}

.btn-ac:hover{
    background-color: var(--acentHover);
}

.smi{
    display: flex;
    justify-content: space-between;
    gap: 100px;
}

.device-wait{
    background-color: transparent;
    border: 2px solid rgba(var(--whiteRGB), .05);
    border-radius: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    padding: 40px;
    aspect-ratio: 1 / 1;
}

html,
body{
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
    height: 100%;
    background: var(--back-lin) no-repeat;
    font-family: 'Inter', sans-serif;
    cursor: default;
    overflow: hidden;
}

body::-webkit-scrollbar{
    background-color: #171717;
    width: 3px;
}

body::-webkit-scrollbar-thumb{
    background-color: rgba(var(--whiteRGB), .25);
}

.fade-in-bottom{
    animation: fade-in-bottom .35s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.semi-wrap{
    display: flex;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    align-items: center;
}

.com-div{
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;
}

.base-h{
    color: white;
    font-size: 28px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 4px;
}

.base-m{
    color: white;
    font-size: 18px;
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 3px;
}

.toast{
    display: flex;
    position: absolute;
    bottom: 5%;
    padding: 10px;
    border-radius: 6px;
    z-index: 200;
    background-color: rgba(var(--whiteRGB), .03);
    width: 20%;
    border: 1px solid rgba(var(--whiteRGB), .1);
    height: 100px;
}

.toast-text{
    color: white;
    font-size: 14px;
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
}

.desc-p{
    color: rgba(var(--whiteRGB), .45);
    font-size: 15px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 1px;
}

.app-head{
    width: -webkit-fill-available;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(var(--whiteRGB), .1);
}

.app-logo{
    color: rgba(var(--whiteRGB), 1);
    font-size: 12px;
    font-family: 'Orbitron';
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 5px;
}

.desc-px{
    color: rgba(var(--whiteRGB), .45);
    font-size: 13px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 1px;
}

.fileCard{
    border: 1px solid rgba(var(--whiteRGB), .1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 50px;
}

.fileLead{
    position: absolute;
    z-index: 500;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    padding: 15px;
}

.fileProgress{
    position: absolute;
    z-index: 400;
    background-color: rgba(var(--whiteRGB), .03);
    width: 0%;
    height: -webkit-fill-available;
    max-width: -webkit-fill-available;
    transition: all 3s cubic-bezier(0.300, 0.575, 0.565, 1.000);
}

.transfers{
    margin-top: 50px;
}

.transport-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.get-svg > path,
.get-svg > g{
    fill: var(--accent);
}

.fileCardDet{
    display: flex;
    gap: 10px;
    align-items: center;
}

.fileName{
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 1px;
}

.fileSize{
    color: rgba(var(--whiteRGB), .45);
    font-size: 12px;
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0;
}

.net-state-wait{
    color: var(--yellow) !important;
    animation: blink 2s cubic-bezier(0.300, 0.575, 0.565, 1.000) infinite;
}

.net-state-ok{
    color: var(--green) !important;
}

.net-state-err{
    color: var(--red) !important;
    animation: blink 2s cubic-bezier(0.300, 0.575, 0.565, 1.000) infinite;
}

.tab-list a{
    text-decoration: none !important;
}

.blink{
    animation: blink 2s cubic-bezier(0.300, 0.575, 0.565, 1.000) infinite;
}

@keyframes blink {
    0%{
        opacity: .25;
    }

    25%{
        opacity: .5;
    }

    50%{
        opacity: 1;
    }

    75%{
        opacity: .5;
    }

    100%{
        opacity: .25;
    }
}

b{
    color: white !important;
}

.fade-in-right{
    animation: fade-in-right .25s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

.loaderx {
    height: 5px;
    width: 5px;
    color: #fff;
    box-shadow: -10px -10px 0 5px,
    -10px -10px 0 5px,
    -10px -10px 0 5px,
    -10px -10px 0 5px;
    animation: loader-38 4s infinite;
}

@keyframes loader-38 {
    0% {
        box-shadow: -10px -10px 0 5px,
        -10px -10px 0 5px,
        -10px -10px 0 5px,
        -10px -10px 0 5px;
    }
    8.33% {
        box-shadow: -10px -10px 0 5px,
        10px -10px 0 5px,
        10px -10px 0 5px,
        10px -10px 0 5px;
    }
    16.66% {
        box-shadow: -10px -10px 0 5px,
        10px -10px 0 5px,
        10px 10px 0 5px,
        10px 10px 0 5px;
    }
    24.99% {
        box-shadow: -10px -10px 0 5px,
        10px -10px 0 5px,
        10px 10px 0 5px,
        -10px 10px 0 5px;
    }
    33.32% {
        box-shadow: -10px -10px 0 5px,
        10px -10px 0 5px,
        10px 10px 0 5px,
        -10px -10px 0 5px;
    }
    41.65% {
        box-shadow: 10px -10px 0 5px,
        10px -10px 0 5px,
        10px 10px 0 5px,
        10px -10px 0 5px;
    }
    49.98% {
        box-shadow: 10px 10px 0 5px,
        10px 10px 0 5px,
        10px 10px 0 5px,
        10px 10px 0 5px;
    }
    58.31% {
        box-shadow: -10px 10px 0 5px,
        -10px 10px 0 5px,
        10px 10px 0 5px,
        -10px 10px 0 5px;
    }
    66.64% {
        box-shadow: -10px -10px 0 5px,
        -10px -10px 0 5px,
        10px 10px 0 5px,
        -10px 10px 0 5px;
    }
    74.97% {
        box-shadow: -10px -10px 0 5px,
        10px -10px 0 5px,
        10px 10px 0 5px,
        -10px 10px 0 5px;
    }
    83.3% {
        box-shadow: -10px -10px 0 5px,
        10px 10px 0 5px,
        10px 10px 0 5px,
        -10px 10px 0 5px;
    }
    91.63% {
        box-shadow: -10px -10px 0 5px,
        -10px 10px 0 5px,
        -10px 10px 0 5px,
        -10px 10px 0 5px;
    }
    100% {
        box-shadow: -10px -10px 0 5px,
        -10px -10px 0 5px,
        -10px -10px 0 5px,
        -10px -10px 0 5px;
    }
}



@keyframes fade-in-right {
    0%{
        opacity: 0;
        transform: translateX(50px);
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

.fade-out-left{
    animation: fade-out-left .25s cubic-bezier(0.300, 0.575, 0.565, 1.000) both;
}

@keyframes fade-out-left {
    0%{
        opacity: 1;
        transform: translateX(0);
    }

    100%{
        opacity: 0;
        transform: translateX(-50px);
    }
}

@keyframes fade-in-bottom {
    0%{
        opacity: 0;
        transform: translateY(250px);
    }

    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-out-top {
    0%{
        opacity: 1;
        transform: translateY(0);
    }

    100%{
        opacity: 0;
        transform: translateY(250px);
    }
}